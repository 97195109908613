/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-syntax */
/* eslint-disable eqeqeq */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-unused-vars */
/* eslint-disable no-alert */
/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable import/no-cycle */
import React, { useRef } from 'react';
import {
  GridComponent,
  Inject,
  ColumnsDirective,
  ColumnDirective,
  Search,
  Page,
  Selection, Toolbar, Edit, Sort, Filter, Resize, ForeignKey,
} from '@syncfusion/ej2-react-grids';
import { Query } from '@syncfusion/ej2-data';

// import axios from 'axios';
import { Header } from '.';
import { Methods } from '../pages/Methods';

import '../pages/Zackat.css';

const OTA = () => {
  const usr = JSON.parse(localStorage.getItem('user'));
  const toolbarOptions = ['Search', 'Add', 'Edit', 'Delete'];
  const { REACT_APP_API_ENDPOINT } = process.env;
  const selectionSettings = { mode: 'Row', type: 'Single' };
  const valuerules = { required: true };
  const { loading, data } = Methods('otas', 'GET', '');
  const fmtData = [
    { value: 'BIN', id: 'bin' },
  ];
  const statusData = [
    { value: 'Active', id: 'active' },
    { value: 'Inactive', id: 'inactive' },
    { value: 'Deprecated', id: 'deprecated' },
  ];
  const orgData = [];
  const start = (e) => {
    const device = prompt('Notify device of OTA?', '');
    const message = 'fota';
    const b = { device };
    const payload = { inventory: b, message };
    fetch(`${REACT_APP_API_ENDPOINT}/jasper`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `JWTToken ${usr.token}`,
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        // The added/edited data will be saved in the Grid
      })
      .catch((error) => {
        console.log(error);
      });
  };
  function handleChange(e) {
    let upData = null;
    for (let idx = 0; idx < data.ota.length; idx += 1) {
      if (data.ota[idx]._id === e.target.id) {
        upData = data.ota[idx];
        break;
      }
    }
    const filedata = new FormData();
    const imagedata = e.target.files[0];
    filedata.append('userfile', imagedata);
    if (upData !== null) {
      filedata.append('_id', upData._id);
      filedata.append('fileName', e.target.files[0].name);
    } else {
      return;
    }
    fetch(`${REACT_APP_API_ENDPOINT}/otaup`, {
      method: 'POST',
      headers: {
        Authorization: `JWTToken ${usr.token}`,
      },
      body: filedata,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        data.ota.filesid = responseJson._id;
        window.location.reload();
      })
      .catch((error) => {
        alert('An error has occured during Insert!');
        console.log(error);
      });
  }
  function removeOTA(e) {
    let removeData = null;
    for (let idx = 0; idx < data.ota.length; idx += 1) {
      if (data.ota[idx].filesid === e.target.id) {
        removeData = data.ota[idx];
        break;
      }
    }
    if (removeData == null || confirm(`Remove OTA file for ${removeData.name}?`) === false) {
      return;
    }
    if (removeData !== null) {
      fetch(`${REACT_APP_API_ENDPOINT}/otaup`, {
        method: 'DELETE',
        headers: {
          Authorization: `JWTToken ${usr.token}`,
        },
        body: JSON.stringify(removeData),
      })
        .then((response) => response.json())
        .then((responseJson) => {
          window.location.reload();
        })
        .catch((error) => {
          alert('An error has occured during Removal!');
        });
    }
  }
  const gridOTAProfile = (props) => (
    <div className={`flex items-center gap-2 center 
      ${((props.filesid)
    ) ? 'e-background-colorgreen' : ''}`}
    >
      {!props.filesid
        ? <input type="file" name="userfile" id={props._id} onChange={handleChange} />
        : (
          <div>
            <font color="black"><input type="submit" name="remove" id={props.filesid} value="&#x007F; Remove OTA file" title={props.filesid} onClick={removeOTA} /></font>
          </div>
        )}
    </div>
  );
  const gridOTAHeader = () => (
    <div>
      <pre>Media</pre>
    </div>
  );
  const otaTemplate = (props) => (
    <div>
      {(props.format === 'bin') ? (
        <input type="button" name="download" id={props._id} value="&#x2316;" onClick={start} />
      ) : null}
    </div>
  );
  const editing = {
    allowEditing: true,
    allowAdding: true,
    allowDeleting: true,
    showConfirmDialog: true,
    showDeleteConfirmDialog: true,
    mode: 'Dialog',
  };
  const fmtParams = {
    params: {
      actionComplete: () => false,
      allowFiltering: true,
      dataSource: fmtData,
      fields: { value: 'id', text: 'value' },
      query: new Query(),
    },
  };
  const statusParams = {
    params: {
      actionComplete: () => false,
      allowFiltering: true,
      dataSource: statusData,
      fields: { value: 'id', text: 'value' },
      query: new Query(),
    },
  };
  let grid = useRef(null);
  const actionBegin = (args) => {
    if (!grid || !grid.columns) {
      return;
    }
    const cols = grid.columns;
    for (const col of cols) {
      if (col.headerText === 'Media' && (args.requestType === 'add' || args.requestType === 'beginEdit')) {
        col.visible = false;
      } else if (col.headerText === 'ID' && (args.requestType === 'add' || args.requestType === 'beginEdit')) {
        col.visible = false;
      } else if (col.headerText === 'Media') {
        col.visible = true;
      }
    }
  };
  const actionComplete = (args) => {
    if (args.requestType === 'beginEdit') {
      // eslint-disable-next-line prefer-destructuring
      const dialog = args.dialog;
      dialog.header = `Details of ${args.rowData.name}`;
    }
    if (args.requestType === 'save' && args.form && args.action === 'add') {
      if (args.data && args.data.name) {
        // The default edit operation is cancelled
        // Here you can send the updated data to your server using AJAX call
        orgData.forEach((element) => {
          if (element.id === args.data.organizationid) {
            args.data.organizationid = element.id;
            args.data.organization = element.value;
          }
        });
        fmtData.forEach((element) => {
          if (element.value == args.data.format) {
            element.format = element.id;
          }
        });
        fetch(`${REACT_APP_API_ENDPOINT}/otas`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `JWTToken ${usr.token}`,
          },
          body: JSON.stringify(args.data),
        })
          .then((response) => response.json())
          .then((responseJson) => {
            // The added/edited data will be saved in the Grid
            if (responseJson && responseJson._id) {
              args.data._id = responseJson._id;
              const rowInfo = args.data;
              grid.setRowData(responseJson._id, rowInfo.rowData);
              args.cancel = true;
              grid.endEdit();
              window.location.reload();
            }
          })
          .catch((error) => {
            alert('An error has occured during Insert!');
          });
      }
    } else if (args.requestType === 'save' && args.form && args.action === 'edit') {
      if (args.data) {
        orgData.forEach((element) => {
          if (element.id === args.data.organizationid) {
            args.data.organizationid = element.id;
            args.data.organization = element.value;
          }
        });
        fmtData.forEach((element) => {
          if (element.value == args.data.format) {
            element.format = element.id;
          }
        });
        // The default edit operation is cancelled
        // Here you can send the updated data to your server using AJAX call
        fetch(`${REACT_APP_API_ENDPOINT}/otas`, {
          method: 'UPDATE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `JWTToken ${usr.token}`,
          },
          body: JSON.stringify(args.data),
        })
          .then((response) => response.json())
          .then((responseJson) => {
            // The added/edited data will be saved in the Grid
            args.cancel = true;
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else if (args.requestType === 'delete') {
      let entry = ' this entry?';
      if (args.data.length > 1) {
        entry = ' these entries?';
      }
      if (args.data) {
        // Here you can send the updated data to your server using AJAX call
        fetch(`${REACT_APP_API_ENDPOINT}/otas`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `JWTToken ${usr.token}`,
          },
          body: JSON.stringify(args.data),
        })
          .then((response) => response.json())
          .then(() => {
            // The added/edited data will be saved in the Grid
            // The default edit operation is cancelled
            args.cancel = true;
            grid.endEdit();
          })
          .catch((error) => {
            alert('An error has occured during Delete!');
          });
      } else {
        args.cancel = false;
      }
    }
  };
  if (loading) {
    return <p>Loading... </p>;
  }
  data.organization.forEach((org) => {
    orgData.push({ id: org._id, value: org.title });
  });
  const organizations = {
    params: {
      actionComplete: () => false,
      allowFiltering: true,
      dataSource: data.organization,
      fields: { value: '_id', text: 'title' },
      query: new Query(),
    },
  };

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl zackat-div">
      <Header category="" title="OTA" />
      <GridComponent
        dataSource={data.ota}
        width="auto"
        allowPaging
        allowSorting
        editSettings={editing}
        toolbar={toolbarOptions}
        actionComplete={actionComplete}
        actionBegin={actionBegin}
        selectionSettings={selectionSettings}
        enableHover={false}
        pageSettings={{ pageSize: 25, pageCount: 10 }}
        allowResizing
        allowFiltering
        allowTextWrap
        ref={(g) => { grid = g; }}
      >
        <ColumnsDirective>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <ColumnDirective
            field="_id"
            headerText="ID"
            template={otaTemplate}
            width="100"
            visible
            isPrimaryKey
          />
          <ColumnDirective
            field="name"
            headerText="Name"
            width="150"
            validationRules={valuerules}
            visible
          />
          <ColumnDirective
            field="format"
            headerText="Format"
            width="80"
            validationRules={valuerules}
            visible
            editType="dropdownedit"
            edit={fmtParams}
            foreignKeyField="id"
            foreignKeyValue="value"
            dataSource={fmtParams.params.dataSource}
          />
          <ColumnDirective
            field="status"
            headerText="Status"
            width="80"
            validationRules={valuerules}
            visible
            editType="dropdownedit"
            edit={statusParams}
            foreignKeyField="id"
            foreignKeyValue="value"
            dataSource={statusParams.params.dataSource}
          />
          <ColumnDirective
            field="organizationid"
            headerText="Organization"
            width="150"
            textAlign="Center"
            editType="dropdownedit"
            edit={organizations}
            validationRules={valuerules}
            visible
            foreignKeyField="_id"
            foreignKeyValue="title"
            dataSource={organizations.params.dataSource}
          />
          <ColumnDirective
            field="filesid"
            headerText="Media"
            width="150"
            visible
            template={gridOTAProfile}
            headerTemplate={gridOTAHeader}
          />
        </ColumnsDirective>
        <Inject services={[Search, Page, Selection, Toolbar, Edit, Sort, Filter, Resize, ForeignKey]} />
      </GridComponent>
    </div>
  );
};
export default OTA;
