/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-restricted-syntax */
/* eslint-disable eqeqeq */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-alert */
/* eslint-disable no-param-reassign */
/* eslint-disable import/no-cycle */
import React, { useState, useEffect, useRef } from 'react';
import {
  GridComponent,
  Inject,
  ColumnsDirective,
  ColumnDirective,
  Search,
  Sort,
  Filter,
  Resize,
  Edit,
  Toolbar,
  Page,
  PdfExport,
  ExcelExport,
  ForeignKey,
  LazyLoadGroup,
  VirtualScroll,
  Group,
} from '@syncfusion/ej2-react-grids';
import { UploaderComponent } from '@syncfusion/ej2-react-inputs';
import { useNavigate, Link } from 'react-router-dom';
import { DataManager, Query } from '@syncfusion/ej2-data';
import axios from 'axios';
import { AMap, Header, useWindowDimensions } from '../components';
import { Methods } from './Methods';
import { useStateContext } from '../contexts/ContextProvider';
import orgpng from '../data/organizations_on orange.png';
import lngpng from '../data/language.png';
import name from '../data/nameicon.png';
import { associatesGrid } from '../data/dummy';

import './Zackat.css';
import profile from '../data/profile.png';

const Associates = () => {
  const usr = JSON.parse(localStorage.getItem('user'));
  const selectedOrg = JSON.parse(localStorage.getItem('organization'));
  const [enableDetails, setDetails] = useState(true);
  const [enableEvents, setEvents] = useState(true);
  const currentThemeColor = localStorage.getItem('colorMode');
  // const selectionSettings = { mode: 'Row', type: 'Single' };
  const details = { text: 'Details', tooltipText: 'Associate Details', prefixIcon: 'e-expand', id: 'details', disabled: enableDetails };
  const events = { text: 'Events', tooltipText: 'Associate Events', prefixIcon: 'e-justify', id: 'events', disabled: enableEvents };
  const alerts = { text: 'Alerts', tooltipText: 'Clear Alerts', prefixIcon: 'e-cut', id: 'alerts', disabled: enableEvents };
  const invite = { text: 'Invite', tooltipText: 'MobileApp Invitation', prefixIcon: 'e-resize', id: 'invite', disabled: enableDetails };
  const toolbarOptions = ['Search', 'Add', 'Edit', 'Delete', details, events, alerts, invite, 'PdfExport', 'ExcelExport'];
  const { REACT_APP_API_ENDPOINT } = process.env;
  const langData = [
    { value: 'English', id: 'en-US' },
    { value: 'German', id: 'de-DE' },
    { value: 'French', id: 'fr-FR' },
    { value: 'French (Canadian)', id: 'fr-CA' },
    { value: 'Spanish (US)', id: 'es-US' },
    { value: 'Spanish', id: 'es-ES' },
    { value: 'Portugese (Brazil)', id: 'pt-BR' },
    { value: 'Portugese (Portugal)', id: 'pt-PT' },
    { value: 'Danish', id: 'da-DK' },
    { value: 'Dutch', id: 'nl-NL' },
    { value: 'Austrailian', id: 'en-AU' },
    { value: 'English (British)', id: 'en-GB' },
    { value: 'English (India)', id: 'en-IN' },
    { value: 'English (Welsh)', id: 'en-GB-WLS' },
    { value: 'Icelandic', id: 'is-IS' },
    { value: 'Italian', id: 'it-IT' },
    { value: 'Japnense', id: 'ja-JP' },
    { value: 'Korean', id: 'ko-KR' },
    { value: 'Norweigan', id: 'nb-NO' },
    { value: 'Polish', id: 'pl-PL' },
    { value: 'Romanian', id: 'ro-RO' },
    { value: 'Russian', id: 'ru-RU' },
    { value: 'Swedish', id: 'sv-SE' },
    { value: 'Turkish', id: 'tr-TR' },
    { value: 'Welsh', id: 'cy-GB' },
    { value: 'Chinese (Simplified)', id: 'zh-CN' },
    { value: 'Chinese (Traditional)', id: 'zh-TW' },
  ];
  const { loading, data } = Methods('associates', 'GET', '');

  /*

  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    // limit associates to the overall selection
    if (selectedOrg && selectedOrg.id && selectedOrg.id.length > 0) {
      usr.associateid = selectedOrg.id;
    }
    // fetch data
    const dataFetch = async () => {
      const query = Object.entries(usr)
        .map(([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`)
        .join('&');
      const url = `${REACT_APP_API_ENDPOINT}/associates?${query}`;
      const mydata = await (
        await fetch(
          url,
        )
      ).json();

      // set state when the data received
      setData(mydata);
      setLoading(false);
    };
    dataFetch();
  }, []);
  */
  const { height, width } = useWindowDimensions();
  const { activeMenu } = useStateContext();
  function formatPhoneNumber(phoneNumberString) {
    if (phoneNumberString.length <= 0) {
      return null;
    }
    if (phoneNumberString.length === 10) {
      phoneNumberString = `1${phoneNumberString}`;
    }
    const cleaned = (`${phoneNumberString}`).replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      const intlCode = (match[1] ? '+1 ' : '');
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return null;
  }
  const gridOrgHeader = () => (
    <div className="">
      <img
        className="w-10 h-10"
        src={orgpng}
        alt="organization"
      />
      <pre>Organization</pre>
    </div>
  );
  const gridNameHeader = () => (
    <div className="">
      <img
        className="w-10 h-10"
        src={name}
        alt="name"
      />
      <pre>Name</pre>
    </div>
  );
  const gridLangHeader = () => (
    <div className="">
      <img
        className="w-10 h-10"
        src={lngpng}
        alt="language"
      />
      <pre>Language</pre>
    </div>
  );
  const groupOptions = {
    enableLazyLoading: true,
    columns: ['organizationid', 'jobTask', 'reportsTo'],
  };
  const {
    screenSize,
    setScreenSize,
  } = useStateContext();
  const [file, setFile] = useState();
  const orgData = [];
  const langParams = {
    params: {
      actionComplete: () => false,
      dataSource: new DataManager(langData),
      fields: { value: 'id', text: 'value' },
      query: new Query(),
    },
  };
  let grid = useRef(null);
  function handleChange(e) {
    let upData = null;
    for (let idx = 0; idx < data.associates.length; idx += 1) {
      if (data.associates[idx]._id === e.target.id) {
        upData = data.associates[idx];
        break;
      }
    }
    const filedata = new FormData();
    const imagedata = e.target.files[0];
    filedata.append('userfile', imagedata);
    if (upData !== null) {
      filedata.append('_id', upData._id);
      filedata.append('fileName', e.target.files[0].name);
    } else {
      return;
    }
    fetch(`${REACT_APP_API_ENDPOINT}/fileup`, {
      method: 'POST',
      headers: {
        Authorization: `JWTToken ${usr.token}`,
      },
      body: filedata,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        window.location.reload();
      })
      .catch((error) => {
        alert('An error has occured during Insert!');
        console.log(error);
      });
  }
  function removePhoto(e) {
    let removeData = null;
    for (let idx = 0; idx < data.associates.length; idx += 1) {
      if (data.associates[idx]._id === e.target.id) {
        removeData = data.associates[idx];
        break;
      }
    }
    if (removeData == null || confirm(`Remove photo for ${removeData.name}?`) === false) {
      console.log('Do not delete');
      return;
    }
    if (removeData !== null) {
      fetch(`${REACT_APP_API_ENDPOINT}/fileup`, {
        method: 'DELETE',
        headers: {
          Authorization: `JWTToken ${usr.token}`,
        },
        body: JSON.stringify(removeData),
      })
        .then((response) => response.json())
        .then((responseJson) => {
          window.location.reload();
        })
        .catch((error) => {
          alert('An error has occured during Removal!');
        });
    }
  }
  const gridAssociateProfile = (props) => (
    <div className={`flex items-center gap-2 center 
      ${((props.activity.alert && props.activity.alert.length > 0)
        || (props.heartrate.alert && props.heartrate.alert.length > 0)
        || (props.bloodpressure.alert && props.bloodpressure.length > 0)
        || (props.hydration.alert && props.hydration.alert.length > 0)
        || (props.temperature.alert && props.temperature.alert.length > 0)
        || (props.oxygen.alert && props.oxygen.alert.length > 0)
        || (props.hrv.alert && props.hrv.alert.length > 0)) ? 'e-background-colorred' : ''}`}
    >
      {!props.employeeImage
        ? <input type="file" name="userfile" id={props._id} onChange={handleChange} />
        : (
          <div>
            <img
              className="rounded-full w-10 h-10"
              src={props.employeeImage ? props.employeeImage : profile}
              alt="employee"
            />
            <font color="black"><input type="submit" name="remove" id={props._id} value="&#x007F;" onClick={removePhoto} /></font>
            <br /><Link to={`tel:${props.mobile}`} style={{ backgroundColor: 'white', color: '#F6821E', textDecoration: 'underline !important' }}>{formatPhoneNumber(props.mobile)}</Link>
          </div>
        )}
    </div>
  );
  const gridImageHeader = () => (
    <div>
      <img
        className="flex w-10 h-10"
        src={profile}
      />
      <pre>Image</pre>
    </div>
  );
  let crudUpdate = false;
  let crudCreate = false;
  let crudDelete = false;
  let myScreenSize = 'auto';
  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  function customiseCell(args) {
    const temp = args.data.temperature.reading;
    const hydration = args.data.hydration.reading;

    switch (args.column.index) {
      case 1:
      case 2:
        break;
      case 3:
        if (temp >= 101) {
          args.cell.classList.add('below-30');
        } else if (temp > 99 && temp < 101) {
          args.cell.classList.add('below-80');
        }
        break;
      case 6:
        if (hydration <= 30) {
          args.cell.classList.add('below-30');
        } else if (hydration > 30 && hydration < 70) {
          args.cell.classList.add('below-80');
        } else {
          args.cell.classList.add('above-80');
        }
        break;
      default:
        break;
    }
  }

  const actionComplete = (args) => {
    const org = JSON.parse(localStorage.getItem('organization'));
    /** Set initial Focus */
    if (args.requestType === 'beginEdit') {
      args.form.elements.namedItem('name').focus();
      // eslint-disable-next-line prefer-destructuring
      const dialog = args.dialog;
      dialog.header = `Details of ${args.rowData.name}`;
    } else if (args.requestType === 'add') {
      args.form.elements.namedItem('name').focus();
    } else if (args.requestType === 'save' && args.form && args.action === 'add') {
      if (args.data && args.data.name.length > 0) {
        // The default edit operation is cancelled
        // Here you can send the updated data to your server using AJAX call
        if (!args.data.organizationid || args.data.organizationid.length === 0) {
          args.data.organizationid = org.id;
          args.data.organization = org.name;
        }
        fetch(`${REACT_APP_API_ENDPOINT}/associates`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `JWTToken ${usr.token}`,
          },
          body: JSON.stringify(args.data),
        })
          .then((response) => response.json())
          .then((responseJson) => {
            // The added/edited data will be saved in the Grid
            grid.endEdit();
            args.cancel = true;
            if (responseJson && responseJson._id) {
              args.data._id = responseJson._id;
            }
          })
          .catch((error) => {
            alert('An error has occured during Insert!');
          });
      }
    } else if (args.requestType === 'save' && args.form && args.action === 'edit') {
      if (args.data) {
        // Here you can send the updated data to your server using AJAX call
        orgData.forEach((element) => {
          if (element.id === args.data.organizationid) {
            args.data.organizationid = element.id;
            args.data.organization = element.value;
          }
        });
        fetch(`${REACT_APP_API_ENDPOINT}/associates`, {
          method: 'UPDATE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `JWTToken ${usr.token}`,
          },
          body: JSON.stringify(args.data),
        })
          .then((response) => response.json())
          .then(() => {
            // The added/edited data will be saved in the Grid
            // The default edit operation is cancelled
            grid.endEdit();
            args.cancel = true;
          })
          .catch((error) => {
            alert('An error has occured during Update!');
          });
      }
    } else if (args.requestType === 'delete') {
      let entry = ' this entry?';
      if (args.data.length > 1) {
        entry = ' these entries?';
      }
      if (args.data) {
        // Here you can send the updated data to your server using AJAX call
        fetch(`${REACT_APP_API_ENDPOINT}/associates`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `JWTToken ${usr.token}`,
          },
          body: JSON.stringify(args.data),
        })
          .then((response) => response.json())
          .then(() => {
            // The added/edited data will be saved in the Grid
            // The default edit operation is cancelled
            grid.endEdit();
            args.cancel = true;
          })
          .catch((error) => {
            alert('An error has occured during Delete!');
          });
      }
    }
  };
  const actionBegin = (args) => {
    if (!grid || !grid.columns) {
      return;
    }
    const cols = grid.columns;
    for (const col of cols) {
      if (col.headerText === 'Image' || col.headerText === 'Events' || col.headerText === 'Supervisor') {
        col.allowEditing = false;
      }
      if (args.requestType == 'add' && col.headerText === 'ID') {
        col.visible = false;
      }
      if (args.requestType == 'add' && col.headerText === 'Organization') {
        // col.visible = false;
      }
      if (args.requestType == 'filter' && (col.headerText == 'Image' || col.headerText == 'Name' || col.headerText == 'Device' || col.headerText == 'Temperature' || col.headerText == 'Bloodpressure'
        || col.headerText == 'Oxygen' || col.headerText == 'Heartrate' || col.headerText == 'HR Variability' || col.headerText == 'Hydration' || col.headerText == 'Fatigue'
        || col.headerText == 'Activity' || col.headerText == 'Organization' || col.headerText == 'Job Task')) {
        // && (col.headerText == 'Device' || col.headerText == 'Temperature' || col.headerText == 'Bloodpressure' || col.headerText == 'Oxygen' || col.headerText == 'Heartrate' || col.headerText == 'HR Variability' || col.headerText == 'Hydration' || col.headerText == 'Fatigue' || col.headerText == 'Activity')) {
        col.visible = true;
      } else if (args.requestType == 'beginEdit') {
        if (col.headerText == 'Image' || col.headerText == 'Man Down' || col.headerText == 'Temperature' || col.headerText == 'Bloodpressure' || col.headerText == 'Oxygen' || col.headerText == 'Heartrate' || col.headerText == 'Hydration' || col.headerText == 'HR Variability' || col.headerText == 'Fatigue' || col.headerText == 'Activity' || col.headerText == 'ID') {
          col.visible = false;
        } else {
          col.visible = true;
        }
      } else if (args.requestType == 'add') {
        if (col.headerText == 'Man Down' || col.headerText == 'Temperature' || col.headerText == 'Bloodpressure' || col.headerText == 'Oxygen' || col.headerText == 'Heartrate' || col.headerText == 'HR Variability' || col.headerText == 'Hydration' || col.headerText == 'Fatigue' || col.headerText == 'Activity') {
          col.visible = false;
        } else {
          col.visible = true;
        }
      } else if (col.headerText == 'Image' || col.headerText == 'Name' || col.headerText == 'Device' || col.headerText == 'Temperature' || col.headerText == 'Bloodpressure'
        || col.headerText == 'Oxygen' || col.headerText == 'Heartrate' || col.headerText == 'HR Variability' || col.headerText == 'Hydration' || col.headerText == 'Fatigue'
        || col.headerText == 'Activity' || col.headerText == 'Man Down' || col.headerText == 'Organization' || col.headerText == 'Job Task') {
        col.visible = true;
      } else {
        // console.log('ELSE', col.headerText);
        col.visible = false;
      }
    }
  };
  function completeUpload(args) {
    alert('Your upload is complete!');
  }
  function rowClicked() {
    setDetails(false);
    setEvents(false);
  }
  let uploadObj;
  const path = {
    removeUrl: `${REACT_APP_API_ENDPOINT}/associatesremove`,
    saveUrl: `${REACT_APP_API_ENDPOINT}/associatesupload`,
  };
  const buttons = { browse: 'Choose File', clear: 'Clear All', upload: 'Upload All' };
  function onFileUpload(args) {
    // add addition data as key-value pair.
    args.customFormData = [{ organizationid: data.user.organizationid }];
  }
  const FilterOptions = {
    type: 'Menu',
  };

  const navigate = useNavigate();
  /*
  const doubleClickHandler = (args) => {
    // grid.preventClick = true;
    navigate(`../basic?id=${args.rowData._id}&lat=${args.rowData.latlng.latitude}&lng=${args.rowData.latlng.longitude}`);
  };
  */
  const clickHandler = (args) => {
    if (grid && args.item.properties.text === 'Details') {
      const selectedRecords = grid.getSelectedRecords();
      if (!selectedRecords || selectedRecords.length === 0) {
        alert('Please select an associate');
      } else if (selectedRecords && selectedRecords.length === 1) {
        navigate(`../basic?id=${selectedRecords[0]._id}&lat=${selectedRecords[0].latlng.latitude}&lng=${selectedRecords[0].latlng.longitude}&name=${selectedRecords[0].name}`);
      } else {
        alert('Please select only one associate');
      }
    } else if (grid && args.item.properties.text === 'Events') {
      const selectedRecords = grid.getSelectedRecords();
      if (!selectedRecords || selectedRecords.length === 0) {
        alert('Please select an associate');
      } else if (selectedRecords && selectedRecords.length === 1) {
        navigate(`../events?id=${selectedRecords[0]._id}&lat=${selectedRecords[0].latlng.latitude}&lng=${selectedRecords[0].latlng.longitude}&name=${selectedRecords[0].name}`);
      } else {
        alert('Please select only one associate');
      }
    } else if (grid && args.item.properties.text === 'Invite') {
      const selectedRecords = grid.getSelectedRecords();
      const recipient = prompt('Please enter the email address of the Associate that you would like to receive the invitation!', selectedRecords[0].email);
      if (recipient) {
        fetch(`${REACT_APP_API_ENDPOINT}/invite?associateid=${selectedRecords[0]._id}&recipient=${recipient}&email=${usr.email}`, {
          method: 'GET',
          headers: {
            Authorization: `JWTToken ${usr.token}`,
          },
        })
          .then((response) => response.json())
          .then((responseJson) => {
            alert('Invitation sent');
          })
          .catch((error) => {
            alert('An error has occured during Invitation request!');
            console.log(error);
          });
      }
    } else if (grid && args.item.properties.text === 'Alerts') {
      const selectedRecords = grid.getSelectedRecords();
      if (!selectedRecords || selectedRecords.length === 0) {
        alert('Please select an associate');
      } else if (selectedRecords && selectedRecords.length === 1) {
        const updateAssociate = async () => {
          selectedRecords[0].activity.alert = '';
          selectedRecords[0].oxygen.alert = '';
          selectedRecords[0].heartrate.alert = '';
          selectedRecords[0].hrv.alert = '';
          selectedRecords[0].temperature.alert = '';
          selectedRecords[0].hydration.alert = '';
          selectedRecords[0].bloodpressure.alert = '';
          const newData = selectedRecords[0];
          const config = {
            Authorization: `JWTToken ${usr.token}`,
          };

          axios.put(
            `${REACT_APP_API_ENDPOINT}/associates`,
            newData,
            config,
          ).then((response) => {
            // Handle the response
            window.location.reload();
            // data.associates = selectedRecords;
          })
            .catch((error) => {
              // Handle errors
            });
        };
        updateAssociate();
      } else {
        alert('Please select only one associate');
      }
    } else if (grid && args.item.properties.text === 'PDF Export') {
      const properties = {
        fileName: `PDFExport_Associates_${new Date().toISOString()}.pdf`,
        pageOrientation: 'Landscape',
      };
      grid.pdfExport(properties);
    } else if (grid && args.item.properties.text === 'Excel Export') {
      const properties = {
        fileName: `ExcelExport_Associates_${new Date().toISOString()}.xlsx`,
      };
      grid.excelExport(properties);
    }
  };
  if (activeMenu === false) {
    myScreenSize = 'auto';
  } else {
    const s = (screenSize - 450).toString();
    myScreenSize = `${s}px`;
  }
  /*
  if (!selectedOrg || !selectedOrg.id) {
    return <p>-- Please select and organization -- </p>;
  }
  */
  if (loading) {
    return <p>Loading... </p>;
  }
  if (data.role && data.role.associates) {
    if (data.role.associates.permissions.includes('Create')) {
      crudCreate = true;
    }
    if (data.role.associates.permissions.includes('Update')) {
      crudUpdate = true;
    }
    if (data.role.associates.permissions.includes('Delete')) {
      crudDelete = true;
    }
    if (!data.role.associates.permissions.includes('Read')) {
      return <p>Not authorized...</p>;
    }
  }
  const editOptions = {
    allowEditing: crudUpdate,
    allowAdding: crudCreate,
    allowDeleting: crudDelete,
    showConfirmDialog: true,
    showDeleteConfirmDialog: true,
    allowEditOnDblClick: true,
    mode: 'Dialog',
  };
  data.organization.forEach((org) => {
    orgData.push({ id: org._id, value: org.title });
  });

  const organizations = {
    params: {
      actionComplete: () => false,
      dataSource: new DataManager(data.organization),
      fields: { value: '_id', text: 'title' },
      query: new Query(),
    },
  };
  const valuerules = { required: true };
  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl zackat-div">
      <Header category="" title="Associates" />
      <AMap dt={{ data }} />
      <div
        style={{
          backgroundColor: currentThemeColor,
          textAlign: 'right',
        }}
      >
        <div id="droparea">{crudCreate ? 'IMPORT' : ''}
          {crudCreate === true && (
            <UploaderComponent
              id="fileupload"
              type="file"
              ref={(uplaod) => { uploadObj = uplaod; }}
              asyncSettings={path}
              buttons={buttons}
              multiple={false}
              actionComplete={completeUpload}
              autoUpload={false}
              uploading={onFileUpload}
              allowedExtensions=".csv"
            />
          )}
        </div>
      </div>
      {/*
      <div className='control-section'>
        <MapsComponent id="maps" loaded={onMapsLoad.bind(this)} load={load} zoomSettings={{ enable: true, shouldZoomInitially: true }} legendSettings={{ visible: true }} titleSettings={{ text: 'Associate locations', textStyle: { size: '16px' } }}>
          <Inject services={[Marker, Legend, MapsTooltip, Zoom]} />
          <LayersDirective>
            <LayerDirective shapeData={worldMap} shapePropertyPath='continent' shapeDataPath='continent' shapeSettings={{ colorValuePath: 'color' }}>
              <MarkersDirective>
                <MarkerDirective visible={true} template='<div style="font-size: 12px;color:white;text-shadow: 0px 1px 1px black;font-weight: 500;width:50px">{{:name}}</div>' animationDuration={0} dataSource={markers} />
                <MarkerDirective visible={true} shape='Image' imageUrl={'https://maps.google.com/mapfiles/ms/icons/blue.png'} height={20} width={20} animationDuration={0} tooltipSettings={{ visible: true, valuePath: 'name' }} dataSource={markers} />
              </MarkersDirective>
            </LayerDirective>
          </LayersDirective>
        </MapsComponent>
      </div >
      recordDoubleClick={doubleClickHandler}

        */}
      <GridComponent
        dataSource={data.associates}
        width={myScreenSize}
        allowPaging
        allowSorting
        allowResizing
        pageSettings={{ pageSize: 20, pageCount: 5 }}
        editSettings={editOptions}
        filterSettings={FilterOptions}
        allowFiltering
        toolbar={toolbarOptions}
        toolbarClick={clickHandler}
        actionComplete={actionComplete}
        actionBegin={actionBegin}
        queryCellInfo={customiseCell}
        allowGrouping
        allowTextWrap
        allowPdfExport
        allowExcelExport
        recordClick={rowClicked}
        // enableVirtualization
        // enableColumnVirtualization
        // height="600"
        ref={(g) => { grid = g; }}
      >
        <ColumnsDirective>
          <ColumnDirective
            field="employeeImage"
            headerText="Image"
            width="150"
            visible
            template={gridAssociateProfile}
            headerTemplate={gridImageHeader}
          />
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          {associatesGrid.map((item, index) => <ColumnDirective key={index} {...item} />)}
          <ColumnDirective
            field="organizationid"
            headerText="Organization"
            width="150"
            textAlign="Left"
            editType="dropdownedit"
            headerTemplate={gridOrgHeader}
            validationRules={valuerules}
            visible
            foreignKeyField="_id"
            foreignKeyValue="title"
            dataSource={organizations.params.dataSource}
          />
          <ColumnDirective
            field="language"
            headerText="Language"
            width="150"
            visible
            textAlign="Left"
            editType="dropdownedit"
            headerTemplate={gridLangHeader}
            foreignKeyField="id"
            foreignKeyValue="value"
            dataSource={langParams.params.dataSource}
          />
        </ColumnsDirective>
        <Inject services={[Search, Page, Edit, Toolbar, Filter, Sort, Resize, PdfExport, ExcelExport, ForeignKey, Group, LazyLoadGroup, VirtualScroll]} />
      </GridComponent>
    </div>
  );
};
export default Associates;
