/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-alert */
/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-syntax */
/* eslint-disable import/no-cycle */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useRef, useState } from 'react';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Selection,
  Inject,
  Edit,
  Toolbar,
  Resize,
  Sort,
  Filter,
  Search,
  ForeignKey,
} from '@syncfusion/ej2-react-grids';
import { MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns';
import { UploaderComponent } from '@syncfusion/ej2-react-inputs';
import { Query } from '@syncfusion/ej2-data';
import { Methods } from './Methods';
import { Header } from '../components';
import profile from '../data/profile.png';
import './Zackat.css';

const Managers = () => {
  const [enableInvite, setInvite] = useState(true);
  const invite = { text: 'Invite', tooltipText: 'MobileApp Invitation', prefixIcon: 'e-resize', id: 'invite', disabled: enableInvite };
  const toolbarOptions = ['Add', 'Edit', 'Delete', invite, 'Search'];
  const { REACT_APP_API_ENDPOINT } = process.env;
  const valuerules = { required: true };
  const valuerulesnot = { required: false };
  const fields = { value: '_id', text: 'name' };
  let grid = useRef(null);
  const { loading, data } = Methods('managers', 'GET', '');
  const usr = JSON.parse(localStorage.getItem('user'));
  function handleChange(e) {
    let upData = null;
    for (let idx = 0; idx < data.managers.length; idx += 1) {
      if (data.managers[idx]._id === e.target.id) {
        upData = data.managers[idx];
        break;
      }
    }
    const filedata = new FormData();
    const imagedata = e.target.files[0];
    filedata.append('userfile', imagedata);
    if (upData !== null) {
      filedata.append('_id', upData._id);
      filedata.append('fileName', e.target.files[0].name);
    } else {
      return;
    }
    fetch(`${REACT_APP_API_ENDPOINT}/mfileup`, {
      method: 'POST',
      headers: {
        Authorization: `JWTToken ${usr.token}`,
      },
      body: filedata,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        window.location.reload();
      })
      .catch((error) => {
        alert('An error has occured during Insert!');
        console.log(error);
      });
  }
  const clickHandler = (args) => {
    if (grid && args.item.properties.text === 'Invite') {
      const selectedRecords = grid.getSelectedRecords();
      const recipient = prompt('Please enter the email address of the Manager that you would like to receive the invitation!', selectedRecords[0].email);
      if (recipient) {
        fetch(`${REACT_APP_API_ENDPOINT}/invite?associateid=${selectedRecords[0]._id}&manager=true&recipient=${recipient}&email=${usr.email}`, {
          method: 'GET',
          headers: {
            Authorization: `JWTToken ${usr.token}`,
          },
        })
          .then((response) => response.json())
          .then((responseJson) => {
            alert('Invitation sent');
          })
          .catch((error) => {
            alert('An error has occured during Invitation request!');
            console.log(error);
          });
      }
    }
  };
  function removePhoto(e) {
    let removeData = null;
    for (let idx = 0; idx < data.managers.length; idx += 1) {
      if (data.managers[idx]._id === e.target.id) {
        removeData = data.managers[idx];
        break;
      }
    }
    if (removeData == null || confirm(`Remove photo for ${removeData.first} ${removeData.last}?`) === false) {
      return;
    }
    if (removeData !== null) {
      fetch(`${REACT_APP_API_ENDPOINT}/mfileup`, {
        method: 'DELETE',
        headers: {
          Authorization: `JWTToken ${usr.token}`,
        },
        body: JSON.stringify(removeData),
      })
        .then((response) => response.json())
        .then((responseJson) => {
          window.location.reload();
        })
        .catch((error) => {
          alert('An error has occured during Removal!');
        });
    }
  }
  const customerGridImage = (props) => (
    <div className="image flex gap-4">
      {!props.image
        ? <input type="file" name="userfile" id={props._id} onChange={handleChange} />
        : (
          <div>
            <img
              className="rounded-full w-10 h-10"
              src={props.image && props.image.length > 0 ? props.image : profile}
              alt="employee"
            />
            <font color="black"><input type="submit" name="remove" id={props._id} value="&#x007F;" onClick={removePhoto} /></font>
          </div>
        )}
    </div>
  );
  if (loading) {
    return <p>Loading... </p>;
  }
  const orgData = [];
  data.organization.forEach((org) => {
    orgData.push({ id: org._id, value: org.title });
  });
  const associateDatasource = [];
  data.associates.forEach((associate) => {
    associateDatasource.push({ _id: associate._id, name: associate.name });
  });
  const organizations = {
    params: {
      actionComplete: () => false,
      allowFiltering: true,
      dataSource: data.organization,
      fields: { value: '_id', text: 'title' },
      query: new Query(),
    },
  };
  const roles = {
    params: {
      actionComplete: () => false,
      allowFiltering: true,
      dataSource: data.roles,
      fields: { value: '_id', text: 'name' },
      query: new Query(),
    },
  };
  const associates = {
    params: {
      actionComplete: () => false,
      allowFiltering: true,
      dataSource: associateDatasource,
      fields: { value: '_id', text: 'name' },
      query: new Query(),
    },
  };
  let assocRef;
  const associateTemplate = (props) => (
    <div>
      <label>Associates</label>
      <MultiSelectComponent
        id={props.associates}
        ref={(scope) => { assocRef = scope; }}
        dataSource={associateDatasource}
        fields={fields}
        value={props.associates}
      />
    </div>
  );
  const actionComplete = (args) => {
    const editedData = args.data;
    const cols = grid.columns;
    for (const col of cols) {
      if (col.headerText === 'Image') {
        col.allowEditing = false;
      }
      if (col.headerText === 'Associates') {
        col.visible = false;
      }
    }
    /*
    if (!assocRef || !assocRef.value) {
      return;
    }
    editedData.associates = assocRef.value;
    */
    /** Set initial Focus */
    if (args.requestType === 'beginEdit') {
      args.form.elements.namedItem('first').focus();
      // eslint-disable-next-line prefer-destructuring
      const dialog = args.dialog;
      dialog.header = `Details of ${args.rowData.first} ${args.rowData.last}`;
    } else if (args.form && args.requestType === 'add') {
      args.form.elements.namedItem('first').focus();
    } else if (args.requestType === 'save' && args.form && args.action === 'add') {
      if (args.data) {
        // Here you can send the updated data to your server using AJAX call
        orgData.forEach((element) => {
          if (element.id === editedData.organizationid) {
            editedData.organizationid = element.id;
            editedData.organization = element.value;
          }
        });
        fetch(`${REACT_APP_API_ENDPOINT}/managers`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `JWTToken ${usr.token}`,
          },
          body: JSON.stringify(editedData),
        })
          .then((response) => response.json())
          .then((responseJson) => {
            // The added/edited data will be saved in the Grid
            // The default edit operation is cancelled
            args.cancel = true;
            if (responseJson && responseJson._id) {
              args.data._id = responseJson._id;
            }
            grid.endEdit();
          })
          .catch((error) => {
            console.error(error);
          });
      }
    } else if (args.requestType === 'save' && args.form && args.action === 'edit') {
      if (args.data) {
        // The default edit operation is cancelled
        // Here you can send the updated data to your server using AJAX call

        orgData.forEach((element) => {
          if (element.id === editedData.organizationid) {
            editedData.organizationid = element.id;
            editedData.organization = element.value;
          }
        });
        const names = [];
        associateDatasource.forEach((element) => {
          if (editedData.associates.includes(element._id)) {
            names.push(element.name);
          }
        });
        editedData.associatenames = names;
        fetch(`${REACT_APP_API_ENDPOINT}/managers`, {
          method: 'UPDATE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `JWTToken ${usr.token}`,
          },
          body: JSON.stringify(editedData),
        })
          .then((response) => response.json())
          .then((responseJson) => {
            // The added/edited data will be saved in the Grid
            window.location.reload();
          })
          .catch((error) => {
            console.error(error);
          });
      }
    } else if (args.requestType === 'delete') {
      let entry = ' this entry?';
      if (args.data.length > 1) {
        entry = ' these entries?';
      }
      if (args.data) {
        // The default edit operation is cancelled
        // Here you can send the updated data to your server using AJAX call
        fetch(`${REACT_APP_API_ENDPOINT}/managers`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `JWTToken ${usr.token}`,
          },
          body: JSON.stringify(editedData),
        })
          .then((response) => response.json())
          .then((responseJson) => {
            // The added/edited data will be saved in the Grid
            args.cancel = true;
            grid.endEdit();
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }
  };

  const actionBegin = (args) => {
    if (grid && grid.columns) {
      const cols = grid.columns;
      for (const col of cols) {
        if (col.headerText === 'Image') {
          col.allowEditing = false;
          col.visible = false;
        }
        if (col.headerText === 'Associates') {
          col.visible = true;
        }
      }
    }
  };
  function rowClicked() {
    setInvite(false);
  }
  let crudUpdate = false;
  let crudCreate = false;
  let crudDelete = false;
  if (data.role && data.role.associates) {
    if (data.role.associates.permissions.includes('Create')) {
      crudCreate = true;
    }
    if (data.role.associates.permissions.includes('Update')) {
      crudUpdate = true;
    }
    if (data.role.associates.permissions.includes('Delete')) {
      crudDelete = true;
    }
    if (!data.role.associates.permissions.includes('Read')) {
      return <p>Not authorized...</p>;
    }
  }
  const editing = {
    allowEditing: crudUpdate,
    allowAdding: crudCreate,
    allowDeleting: crudDelete,
    showConfirmDialog: true,
    showDeleteConfirmDialog: true,
    mode: 'Dialog',
  };
  function onFileUpload(args) {
    // add addition data as key-value pair.
    args.customFormData = [{ organizationid: data.user.organizationid }];
  }
  function completeUpload(args) {
    window.location.reload();
  }
  let uploadObj;
  const path = {
    removeUrl: `${REACT_APP_API_ENDPOINT}/managersremove`,
    saveUrl: `${REACT_APP_API_ENDPOINT}/managersupload`,
  };
  const buttons = { browse: 'Choose File', clear: 'Clear All', upload: 'Upload All' };

  const FilterOptions = {
    type: 'Menu',
  };
  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl zackat-div">
      <Header category="" title="Managers" />
      <GridComponent
        dataSource={data.managers}
        enableHover={false}
        allowPaging
        pageSettings={{ pageCount: 5 }}
        toolbar={toolbarOptions}
        editSettings={editing}
        allowSorting
        allowResizing
        allowFiltering
        filterSettings={FilterOptions}
        actionComplete={actionComplete}
        actionBegin={actionBegin}
        showColumnChooser
        allowTextWrap
        recordClick={rowClicked}
        toolbarClick={clickHandler}
        ref={(g) => { grid = g; }}
      >
        <ColumnsDirective>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <ColumnDirective
            field="_id"
            headerText="ID"
            width="100"
            format="C2"
            visible={false}
            textAlign="Left"
            isPrimaryKey
          />
          <ColumnDirective
            headerText="Image"
            width="80"
            textAlign="Center"
            template={customerGridImage}
          />
          <ColumnDirective
            field="first"
            headerText="First"
            width="120"
            textAlign="Left"
            validationRules={valuerules}
          />
          <ColumnDirective
            field="last"
            headerText="Last"
            width="120"
            textAlign="Left"
            validationRules={valuerulesnot}
          />
          <ColumnDirective
            field="email"
            headerText="Email"
            validationRules={valuerulesnot}
            width="120"
            textAlign="Left"
          />
          <ColumnDirective
            field="mobile"
            headerText="Mobile"
            validationRules={valuerulesnot}
            width="120"
            textAlign="Left"
          />
          <ColumnDirective
            field="organizationid"
            headerText="Organization"
            width="100"
            textAlign="Left"
            editType="dropdownedit"
            validationRules={valuerules}
            visible
            foreignKeyField="_id"
            foreignKeyValue="title"
            dataSource={organizations.params.dataSource}
          />
          <ColumnDirective
            field="associatenames"
            headerText="Associates"
            textAlign="Left"
            width="114"
            visible
            editTemplate={associateTemplate}
            validationRules={valuerulesnot}
          />
        </ColumnsDirective>

        <Inject services={[Page, Selection, Toolbar, Edit, Sort, Filter, Search, Resize, ForeignKey]} />
      </GridComponent>
      <div id="droparea">
        {crudCreate === true && (
        <UploaderComponent
          id="fileupload"
          type="file"
          ref={(uplaod) => { uploadObj = uplaod; }}
          asyncSettings={path}
          buttons={buttons}
          multiple={false}
          actionComplete={completeUpload}
          autoUpload={false}
          uploading={onFileUpload}
          allowedExtensions=".csv"
        />
        )}
      </div>
    </div>
  );
};

export default Managers;
