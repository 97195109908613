/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-alert */
/* eslint-disable no-use-before-define */
/* eslint-disable no-param-reassign */
/* eslint-disable import/no-cycle */
import React, { useRef } from 'react';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Selection,
  Inject,
  Edit,
  Toolbar,
  Resize,
  Sort,
  Filter,
  ColumnChooser,
  Search,
} from '@syncfusion/ej2-react-grids';

import { MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns';
import billing from '../data/billingbkg.png';
import inventory from '../data/inventorybkg.png';
/*
import roles from '../data/rolesbkg.png';
import users from '../data/rolesbkg.png';
import orgpng from '../data/organizations_on orange.png';
*/
import reports from '../data/reportsbkg.png';
import calendar from '../data/calendarbkg.png';
import alerts from '../data/alerts_orange.png';
import notifications from '../data/orangebell.png';
import dashboard from '../data/dashboardbkg.png';
import associate from '../data/associatesbkg.png';
import namepng from '../data/nameicon.png';
import user3 from '../data/users3.png';
import orgorange from '../data/org_orange.png';
import gear from '../data/gear.png';
import './Zackat.css';
import { Methods } from './Methods';
import { Header } from '../components';

const Roles = () => {
  const toolbarOptions = ['Add', 'Edit', 'Delete', 'Update', 'Cancel'];

  const { REACT_APP_API_ENDPOINT } = process.env;

  let grid = useRef(null);
  const { loading, data } = Methods('roles', 'GET', '');
  const usr = JSON.parse(localStorage.getItem('user'));

  const actionComplete = (args) => {
    const editedData = args.data;
    let entry = ' this entry?';
    if (args.requestType === 'beginEdit') {
      // eslint-disable-next-line prefer-destructuring
      const dialog = args.dialog;
      dialog.header = `Details of ${args.rowData.name}`;
    }
    // console.log('actionComplete', args)
    /** Set initial Focus */
    if (args.requestType === 'save' && args.form && args.action === 'add') {
      if (args.data) {
        editedData.reports.permissions = reportsRef.value;
        editedData.roles.permissions = rolesRef.value;
        editedData.organizations.permissions = organizationsRef.value;
        editedData.users.permissions = usersRef.value;
        editedData.associates.permissions = associatesRef.value;
        editedData.billing.permissions = billingRef.value;
        editedData.notifications.permissions = notificationsRef.value;
        editedData.inventory.permissions = inventoryRef.value;
        editedData.calendar.permissions = calendarRef.value;
        editedData.alerts.permissions = alertsRef.value;
        editedData.dashboard.permissions = dashboardRef.value;

        // Here you can send the updated data to your server using AJAX call
        fetch(`${REACT_APP_API_ENDPOINT}/roles`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `JWTToken ${usr.token}`,
          },
          body: JSON.stringify(editedData),
        })
          .then((response) => response.json())
          .then((responseJson) => {
            // The added/edited data will be saved in the Grid
            // The default edit operation is cancelled
            args.cancel = true;
            grid.endEdit();
            if (responseJson && responseJson._id) {
              args.data._id = responseJson._id;
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
    } else if (args.requestType === 'save' && args.form && args.action === 'edit') {
      if (args.data) {
        if (args.data.length > 1) {
          entry = ' these entries?';
        }
        if (args.data && confirm(`Would you really like to ${args.requestType}${entry}`)) {
          editedData.reports.permissions = reportsRef.value;
          editedData.roles.permissions = rolesRef.value;
          editedData.organizations.permissions = organizationsRef.value;
          editedData.users.permissions = usersRef.value;
          editedData.associates.permissions = associatesRef.value;
          editedData.billing.permissions = billingRef.value;
          editedData.notifications.permissions = notificationsRef.value;
          editedData.inventory.permissions = inventoryRef.value;
          editedData.calendar.permissions = calendarRef.value;
          editedData.alerts.permissions = alertsRef.value;
          editedData.dashboard.permissions = dashboardRef.value;
          // The default edit operation is cancelled
          // Here you can send the updated data to your server using AJAX call
          fetch(`${REACT_APP_API_ENDPOINT}/roles`, {
            method: 'UPDATE',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `JWTToken ${usr.token}`,
            },
            body: JSON.stringify(editedData),
          })
            .then((response) => response.json())
            .then((responseJson) => {
              console.log(responseJson);
              //  The added/edited data will be saved in the Grid
              // args.cancel = true;
              // grid.endEdit();
            })
            .catch((error) => {
              console.error(error);
            });
        }
      }
    } else if (args.requestType === 'delete') {
      if (args.data.length > 1) {
        entry = ' these entries?';
      }
      if (args.data) {
        // The default edit operation is cancelled
        // Here you can send the updated data to your server using AJAX call
        fetch(`${REACT_APP_API_ENDPOINT}/roles`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `JWTToken ${usr.token}`,
          },
          body: JSON.stringify(editedData),
        })
          .then((response) => response.json())
          .then((responseJson) => {
            console.log(responseJson);
            // The added/edited data will be saved in the Grid
            args.cancel = true;
            grid.endEdit();
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }
  };

  if (loading) {
    return <p>Loading... </p>;
  }
  let notificationsRef; let reportsRef; let usersRef; let organizationsRef; let associatesRef; let billingRef; let inventoryRef; let rolesRef; let
    calendarRef; let alertsRef; let dashboardRef;

  let crudUpdate = false;
  let crudCreate = false;
  let crudDelete = false;
  if (data.role.roles.permissions.includes('Create')) {
    crudCreate = true;
  }
  if (data.role.roles.permissions.includes('Update')) {
    crudUpdate = true;
  }
  if (data.role.roles.permissions.includes('Delete')) {
    crudDelete = true;
  }
  if (!data.role.roles.permissions.includes('Read')) {
    return <p>Not authorized...</p>;
  }
  const multiselectDatasource = [
    { value: 'Create', text: 'Create' },
    { value: 'Read', text: 'Read' },
    { value: 'Update', text: 'Update' },
    { value: 'Delete', text: 'Delete' },
  ];
  const fields = { value: 'value', text: 'text' };
  const rolesOrganizationsGridCrud = (props) => (
    <div>
      <label>Organizations</label><MultiSelectComponent id="organizations.permissions" ref={(scope) => { organizationsRef = scope; }} dataSource={multiselectDatasource} fields={fields} value={props.organizations.permissions} />
    </div>
  );
  const rolesUsersGridCrud = (props) => (
    <div>
      <label>Users</label> <MultiSelectComponent id="users.permissions" ref={(scope) => { usersRef = scope; }} dataSource={multiselectDatasource} fields={fields} value={props.users.permissions} />
    </div>
  );
  const rolesNotificationsGridCrud = (props) => (
    <div>
      <label>Notifications</label><MultiSelectComponent id="notifications.permissions" ref={(scope) => { notificationsRef = scope; }} dataSource={multiselectDatasource} fields={fields} value={props.notifications.permissions} />
    </div>
  );
  const rolesAssociatesGridCrud = (props) => (
    <div>
      <label>Associates</label><MultiSelectComponent id="associates.permissions" ref={(scope) => { associatesRef = scope; }} dataSource={multiselectDatasource} fields={fields} closePopupOnSelect value={props.associates.permissions} />
    </div>
  );
  const rolesInventoryGridCrud = (props) => (
    <div>
      <label>Inventory</label><MultiSelectComponent id="inventory.permissions" ref={(scope) => { inventoryRef = scope; }} dataSource={multiselectDatasource} fields={fields} value={props.inventory.permissions} />
    </div>
  );
  const rolesCalendarGridCrud = (props) => (
    <div>
      <label>Calendar</label><MultiSelectComponent id="calendar.permissions" ref={(scope) => { calendarRef = scope; }} dataSource={multiselectDatasource} fields={fields} value={props.calendar.permissions} />
    </div>
  );
  const rolesBillingGridCrud = (props) => (
    <div>
      <label>Billing</label><MultiSelectComponent id="billing.permissions" ref={(scope) => { billingRef = scope; }} dataSource={multiselectDatasource} fields={fields} value={props.billing.permissions} />
    </div>
  );
  const rolesRolesGridCrud = (props) => (
    <div>
      <label>Roles</label><MultiSelectComponent id="roles.permissions" ref={(scope) => { rolesRef = scope; }} dataSource={multiselectDatasource} fields={fields} value={props.roles.permissions} />
    </div>
  );
  const rolesReportsGridCrud = (props) => (
    <div>
      <label>Reports</label><MultiSelectComponent id="reports.permissions" ref={(scope) => { reportsRef = scope; }} dataSource={multiselectDatasource} fields={fields} value={props.reports.permissions} />
    </div>
  );
  const rolesAlertsGridCrud = (props) => (
    <div>
      <label>Alerts</label><MultiSelectComponent id="alerts.permissions" ref={(scope) => { alertsRef = scope; }} dataSource={multiselectDatasource} fields={fields} value={props.alerts.permissions} />
    </div>
  );
  const rolesDashboardGridCrud = (props) => (
    <div>
      <label>Dashboard</label><MultiSelectComponent id="dashboard.permissions" ref={(scope) => { dashboardRef = scope; }} dataSource={multiselectDatasource} fields={fields} value={props.dashboard.permissions} />
    </div>
  );
  const gridNameHeader = () => (
    <div className="">
      <img
        className="w-10 h-10"
        src={namepng}
        alt="name"
      />
      <pre>Name</pre>
    </div>
  );
  const gridBillingHeader = () => (
    <div className="">
      <img
        className="w-10 h-10"
        src={billing}
        alt="billing"
      />
      <pre>Billing</pre>
    </div>
  );
  const gridReportsHeader = () => (
    <div className="">
      <img
        className="w-10 h-10"
        src={reports}
        alt="reports"
      />
      <pre>Reports</pre>
    </div>
  );
  const gridOrgHeader = () => (
    <div className="">
      <img
        className="w-10 h-10"
        src={orgorange}
        alt="building"
      />
      <pre>Organaizations</pre>
    </div>
  );
  const gridInventoryHeader = () => (
    <div className="">
      <img
        className="w-10 h-10"
        src={inventory}
        alt="inventory"
      />
      <pre>Inventory</pre>
    </div>
  );
  const gridCalendarHeader = () => (
    <div className="">
      <img
        className="w-10 h-10"
        src={calendar}
        alt="calendar"
      />
      <pre>Calendar</pre>
    </div>
  );
  const gridDashboardHeader = () => (
    <div className="">
      <img
        className="w-10 h-10"
        src={dashboard}
        alt="dashboard"
      />
      <pre>Dashboard</pre>
    </div>
  );
  const gridAlertsHeader = () => (
    <div className="">
      <img
        className="w-10 h-10"
        src={alerts}
        alt="alerts"
      />
      <pre>Alerts</pre>
    </div>
  );
  const gridNotificationsHeader = () => (
    <div className="">
      <img
        className="w-10 h-10"
        src={notifications}
        alt="notifications"
      />
      <pre>Notifications</pre>
    </div>
  );
  const gridUsersHeader = () => (
    <div className="">
      <img
        className="w-10 h-10"
        src={user3}
        alt="users"
      />
      <pre>Users</pre>
    </div>
  );
  const gridAssociatesHeader = () => (
    <div className="">
      <img
        className="w-10 h-10"
        src={associate}
        alt="associates"
      />
      <pre>Associates</pre>
    </div>
  );
  const gridRolesHeader = () => (
    <div className="">
      <img
        className="w-10 h-10"
        src={gear}
        alt="roles"
      />
      <pre>Roles</pre>
    </div>
  );
  const editing = {
    allowEditing: crudUpdate,
    allowAdding: crudCreate,
    allowDeleting: crudDelete,
    showConfirmDialog: true,
    showDeleteConfirmDialog: true,
    mode: 'Dialog',
  };
  const FilterOptions = {
    type: 'Menu',
  };
  const valuerules = { required: true };

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl zackat-div">
      <Header category="" title="Roles" />
      <GridComponent
        dataSource={data.roles}
        enableHover
        allowPaging
        pageSettings={{ pageCount: 5 }}
        toolbar={toolbarOptions}
        editSettings={editing}
        allowSorting
        allowResizing
        allowFiltering
        filterSettings={FilterOptions}
        actionComplete={actionComplete}
        showColumnChooser
        allowTextWrap
        ref={(g) => { grid = g; }}
      >
        <ColumnsDirective>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          {/* rolesGrid.map((item, index) => <ColumnDirective key={index} {...item} />) */}
          <ColumnDirective field="_id" headerText="ID" textAlign="Left" isPrimaryKey width="80" visible={false} />
          <ColumnDirective field="name" headerTemplate={gridNameHeader} headerText="Name" textAlign="Left" width="120" validationRules={valuerules} />
          <ColumnDirective field="billing.permissions" headerText="Billing" headerTemplate={gridBillingHeader} textAlign="Left" width="120" editTemplate={rolesBillingGridCrud} />
          <ColumnDirective field="reports.permissions" headerTemplate={gridReportsHeader} headerText="Reports" textAlign="Left" width="120" editTemplate={rolesReportsGridCrud} />
          <ColumnDirective field="dashboard.permissions" headerTemplate={gridDashboardHeader} headerText="Dashboard" textAlign="Left" width="120" editTemplate={rolesDashboardGridCrud} />
          <ColumnDirective field="organizations.permissions" headerTemplate={gridOrgHeader} headerText="Organizations" textAlign="Left" width="120" editTemplate={rolesOrganizationsGridCrud} />
          <ColumnDirective field="users.permissions" headerTemplate={gridUsersHeader} headerText="Users" textAlign="Left" width="120" editTemplate={rolesUsersGridCrud} />
          <ColumnDirective field="associates.permissions" headerTemplate={gridAssociatesHeader} headerText="Associates" textAlign="Left" width="120" editTemplate={rolesAssociatesGridCrud} />
          <ColumnDirective field="inventory.permissions" headerTemplate={gridInventoryHeader} headerText="Inventory" textAlign="Left" width="120" editTemplate={rolesInventoryGridCrud} />
          <ColumnDirective field="alerts.permissions" headerTemplate={gridAlertsHeader} headerText="Alerts" textAlign="Left" width="120" editTemplate={rolesAlertsGridCrud} />
          <ColumnDirective field="calendar.permissions" headerTemplate={gridCalendarHeader} headerText="Calendar" textAlign="Left" width="120" editTemplate={rolesCalendarGridCrud} />
          <ColumnDirective field="notifications.permissions" headerTemplate={gridNotificationsHeader} headerText="Notifications" textAlign="Left" width="120" editTemplate={rolesNotificationsGridCrud} />
          <ColumnDirective field="roles.permissions" headerTemplate={gridRolesHeader} headerText="Roles" textAlign="Left" width="120" editTemplate={rolesRolesGridCrud} />
        </ColumnsDirective>
        <Inject services={[Search, Page, Selection, Toolbar, Edit, Sort, Filter, ColumnChooser, Resize]} />
      </GridComponent>
    </div>
  );
};

export default Roles;
