/* eslint-disable import/no-cycle */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { MdOutlineCancel } from 'react-icons/md';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';

import { links } from '../data/dummy';
import { useStateContext } from '../contexts/ContextProvider';
import { Methods } from '../pages';
import '../pages/Zackat.css';

const Sidebar = () => {
  const { currentColor, activeMenu, setActiveMenu, screenSize } = useStateContext();
  const currentActiveMenu = localStorage.getItem('activeMenu');
  useEffect(() => {
    localStorage.setItem('activeMenu', activeMenu);
  }, [activeMenu]);

  const handleCloseSideBar = () => {
    if (activeMenu !== undefined && screenSize <= 900) {
      setActiveMenu(false);
    }
  };

  const activeLink = 'flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg  text-white  text-md m-2';
  const normalLink = 'flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg text-md text-gray-700 dark:text-gray-200 dark:hover:text-black hover:bg-light-gray m-2 monacoHover';
  const { loading, data } = Methods('user', 'GET', '');
  if (loading) {
    return <p>Loading... </p>;
  }
  let orgRead = false;
  let associateRead = false;
  let rolesRead = false;
  let inventoryRead = false;
  let usersRead = false;
  let calendarRead = false;
  let alertsRead = false;
  let dashboardRead = false;
  let billingRead = false;
  let reportsRead = false;

  if (data.role.roles.permissions.includes('Read')) {
    rolesRead = true;
  }
  if (data.role.organizations.permissions.includes('Read')) {
    orgRead = true;
  }
  if (data.role.associates.permissions.includes('Read')) {
    associateRead = true;
  }
  if (data.role.inventory.permissions.includes('Read')) {
    inventoryRead = true;
  }
  if (data.role.roles.permissions.includes('Read')) {
    rolesRead = true;
  }
  if (data.role.users.permissions.includes('Read')) {
    usersRead = true;
  }
  if (data.role.calendar.permissions.includes('Read')) {
    calendarRead = true;
  }
  if (data.role.dashboard && data.role.dashboard.permissions && data.role.dashboard.permissions.includes('Read')) {
    dashboardRead = true;
  }
  if (data.role.alerts && data.role.alerts.permissions && data.role.alerts.permissions.includes('Read')) {
    alertsRead = true;
  }
  if (data.role.billing && data.role.billing.permissions && data.role.billing.permissions.includes('Read')) {
    billingRead = true;
  }
  if (data.role.reports && data.role.reports.permissions && data.role.reports.permissions.includes('Read')) {
    reportsRead = true;
  }
  function viewPermission(item) {
    if ((orgRead || usersRead || rolesRead || dashboardRead) && item.title === 'Administration') {
      return true;
    } if ((associateRead || inventoryRead || alertsRead) && item.title === 'Operations') {
      return true;
    } if ((billingRead || reportsRead) && item.title === 'Dashboards') {
      return true;
    } if (calendarRead && item.title === 'Apps') {
      return true;
    } if (orgRead && item.name === 'organizations') {
      return true;
    } if (usersRead && item.name === 'users') {
      return true;
    } if (rolesRead && item.name === 'roles') {
      return true;
    } if (dashboardRead && item.name === 'dashboard') {
      return true;
    } if (inventoryRead && (item.name === 'inventory' || item.name === 'card')) {
      return true;
    } if (associateRead && item.name === 'associates') {
      return true;
    } if (associateRead && item.name === 'managers') {
      return true;
    } if (calendarRead && item.name === 'calendar') {
      return true;
    } if (billingRead && item.name === 'calendar') {
      return true;
    } if (calendarRead && item.name === 'billing') {
      return true;
    } if (reportsRead && item.name === 'reports') {
      return true;
    } if (alertsRead && item.name === 'alerts') {
      return true;
    }
    return false;
  }
  return (
    <div className="ml-3 h-screen md:overflow-hidden overflow-auto md:hover:overflow-auto pb-10">
      {activeMenu && currentActiveMenu && (
        <>
          <div className="flex justify-between items-center" style={{ backgroundColor: '#366976' }}>
            <Link
              to="/"
              onClick={handleCloseSideBar}
              className="items-center gap-3 ml-3 mt-4 flex text-xl font-extrabold tracking-tight dark:text-white text-slate-900"
            >
              <span><img src="./logo_whitetext.png" width="200" height="46" /></span>
              {
              /*
              <span><img src="./logo.png" width="40" height="33" />Monaco<br />SENSO<b>CORE</b></span>
              */
              }
            </Link>
            <TooltipComponent content="Menu" position="BottomCenter">
              <button
                type="button"
                onClick={() => setActiveMenu(!activeMenu)}
                style={{ color: currentColor }}
                className="text-xl rounded-full p-3 hover:bg-light-gray mt-4 block md:hidden"
              >
                <MdOutlineCancel />
              </button>
            </TooltipComponent>
          </div>
          <div className="p-8" style={{ backgroundColor: '#366976' }}>
            {links.map((item) => (viewPermission(item)
              && (
              <div key={item.title}>
                <p
                  className="text-gray-400 dark:text-gray-400 m-3 mt-4 uppercase kern"
                  style={{
                    color: '#58E2F7',
                  }}
                >
                  {item.title}
                </p>
                {item.links.map((link) => (viewPermission(link)
                  && (
                  <NavLink
                    to={`/${link.name}`}
                    key={link.name}
                    onClick={handleCloseSideBar}
                    style={({ isActive }) => ({
                      backgroundColor: isActive ? currentColor : '',
                      color: 'white',
                    })}
                    className={({ isActive }) => (isActive ? activeLink : normalLink)}
                  >
                    {link.icon}
                    <span className="capitalize ">{link.name}</span>
                  </NavLink>
                  )
                ))}
              </div>
              )
            ))}
          </div>
        </>
      )}
    </div>
  );
};
export default Sidebar;
